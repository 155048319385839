const PAID_WITH_CASH = ['c-trailers', 'c-trucks']
exports.PAID_WITH_CASH = PAID_WITH_CASH
const REFINANCED = ['rf-trailers', 'rf-trucks', 'rf-trucks-trailers']
exports.REFINANCED = REFINANCED
const FINANCED = ['f-trailers', 'f-trucks', 'f-trucks-trailers']
exports.FINANCED = FINANCED
const FINANCED_AND_REFINANCED = [...FINANCED, ...REFINANCED]
exports.FINANCED_AND_REFINANCED = FINANCED_AND_REFINANCED
const TRUCK_BACKGROUND = '#D8EAD3'
exports.TRUCK_BACKGROUND = TRUCK_BACKGROUND
const TRAILER_BACKGROUND = '#FFF2CC'
exports.TRAILER_BACKGROUND = TRAILER_BACKGROUND

const yearsList = () => {
  const currentYear = parseInt(new Date().getFullYear() + 1)
  const years = []
  for (let i = currentYear; i >= 2000; i--) years.push(i)
  return years
}

const footerProps = {
  'items-per-page-options': [10, 20, 30, 40, 50],
}

exports.COMMON_DATA = {
  totalItems: 0,
  loading: false,
  items: [],
  footerProps,
  options: {
    page: 1,
    itemsPerPage: 30,
  },
}
const COMMON_UNIT_FILTERS = {
  search: { type: 'text', label: 'VIN', min: 1 },
  yard: {
    type: 'select',
    label: 'Yard',
    itemValue: '_id',
    itemText: 'name',
    options: []
  },
  make: {
    type: 'select',
    label: 'Make',
    itemValue: 'name',
    itemText: 'name',
    options: []
  },
  model: {
    type: 'select',
    label: 'Model/Type',
    itemValue: 'name',
    itemText: 'name',
    options: []
  },
  contractName: {
    type: 'text',
    label: 'Contract',
    min:1
  },
  year: {
    type: 'select',
    label: 'Year',
    options: yearsList()
  },
  unitsType: {
    label: 'Unit type',
    type: 'select',
    options: [
      {
        text: 'Trucks',
        value: 'truck'
      },
      {
        text: 'Trailers',
        value: 'trailer'
      }
    ]
  },
  status: {
    type: 'select',
    label: 'Status',
    options: [
      { header: 'Trailer' },
      'NO STATUS',
      'STOLEN',
      'REPOSSESSION',
      'REPOWER',
      'NEW',
      'ON THE ROAD',
      'SOLO',
      'BROCKERAGE',
      'PARKED',
      'MISSING',
      'RECOVERY',
      'CLAIMS',
      { header: 'Truck' },
      'Total damage',
      'In company - Empty',
      'In company - Reserved',
      'Out of company',
      'Broken',
      'Temporary',
      'Good to go',
      'Auction'
    ]
  },
  bank: {
    type: 'select',
    label: 'Bank',
    itemValue: 'name',
    itemText: 'name',
    options: []
  },
  paid: {
    type: 'select',
    label: 'Paid',
    options: [
      { text: 'Paid', value: true },
      { text: 'Not paid', value: false }
    ]
  },
  financing: {
    type: 'select',
    label: 'Financing',
    options: [
      {
        text: 'Refinanced',
        value: REFINANCED,
      },
      {
        text: 'Financed',
        value: FINANCED_AND_REFINANCED,
      },
      { text: 'Paid with cash', value: PAID_WITH_CASH },
    ],
  },
  company: {
    type: 'combobox',
    label: 'Company',
    options: ['Floyd Inc', 'Ego Express Inc', 'Floyd / Ego Express', 'NATIONAL LEASE', 'H19', 'TEL']
  },
  date: {
    type: 'date-time',
    label: 'Date',
  },
  priceFrom: {
    type: 'text',
    inputType: 'number',
    min: 1,
    label: 'Price From'
  },
  priceTo: {
    type: 'text',
    inputType: 'number',
    min: 1,
    label: 'Price To'
  },
}

const ACCOUNTING_UNIT_FILTERS = {
  priceWithInterestFrom: {
    type: 'text',
    inputType: 'number',
    min: 1,
    label: 'Interest From'
  },
  priceWithInterestTo: {
    type: 'text',
    inputType: 'number',
    min: 1,
    label: 'Interets To'
  },
  balloonPaymentFrom: {
    type: 'text',
    inputType: 'number',
    min: 1,
    label: 'Balloon From'
  },
  balloonPaymentTo: {
    type: 'text',
    inputType: 'number',
    min: 1,
    label: 'Balloon To'
  },
  monthlyPaymentFrom: {
    type: 'text',
    inputType: 'number',
    min: 1,
    label: 'Monthly From'
  },
  monthlyPaymentTo: {
    type: 'text',
    inputType: 'number',
    min: 1,
    label: 'Monthly To'
  },
  downPaymentFrom: {
    type: 'text',
    inputType: 'number',
    min: 1,
    label: 'Down From'
  },
  downPaymentTo: {
    type: 'text',
    inputType: 'number',
    min: 1,
    label: 'Down To'
  }
}

const FINANCING_FILTERS = {
  type: 'select',
  label: 'Financing',
  options: [
    {
      text: 'Both',
      value: FINANCED_AND_REFINANCED
    },
    {
      text: 'Refinanced',
      value: REFINANCED
    },
    {
      text: 'Financed',
      value: FINANCED
    },
  ],
}

exports.FILTERS = {
  COMMON_UNIT_FILTERS,
  ACCOUNTING_UNIT_FILTERS,
  FINANCING_FILTERS,
}

exports.FILTERS_INITIAL = {
  search: null,
  make: null,
  model: null,
  year: null,
  yard: null,
  bank: null,
  financing: null,
  status: null,
  company: null,
  payoffFrom: null,
  payoffTo: null,
  date:null,
  priceWithInterestFrom: null,
  priceWithInterestTo: null,
  balloonPaymentFrom: null,
  balloonPaymentTo: null,
  monthlyPaymentFrom: null,
  monthlyPaymentTo: null,
  downPaymentFrom: null,
  downPaymentTo: null,
}

exports.HEADERS = {
  PAID_WITH_CASH: [
    // za all units i za units paid with cash
    {
      text: 'Truck/Trailer',
      value: 'type',
    },
    { text: 'VIN', value: 'vin_number' },
    { text: 'Make', value: 'make' },
    { text: 'Model/Type', value: 'model' },
    { text: 'Year', value: 'year' },
    { text: 'Location', value: 'location', width: 200 },
    { text: 'Contract / Invoice', value: 'contractName', width: 200 },
    { text: 'Date', value: 'date', width: 200 },
    { text: 'Company', value: 'company', width: 150 },
    { text: 'Financial Institution / Dealer', value: 'bank', width: 200 },
    { text: 'Cash Price', value: 'payoff', width: 200 },
    { text: 'Status', value: 'status', width: 200 },
    { text: 'Actions', value: 'actions', align: 'center' },
  ],
  FINANCED_AND_REFINANCED: [
    // za all units i za units financed
    {
      text: 'Truck/Trailer',
      value: 'type',
    },
    { text: 'VIN', value: 'vin_number' },
    { text: 'Make', value: 'make' },
    { text: 'Model/Type', value: 'model' },
    { text: 'Year', value: 'year' },
    { text: 'Location', value: 'location', width: 200 },
    { text: 'Contract / Invoice', value: 'contractName', width: 200 },
    { text: 'Date', value: 'date', width: 200 },
    { text: 'Company', value: 'company', width: 150 },
    { text: 'Financial Institution / Dealer', value: 'bank', width: 200 },
    { text: 'Type of financing', value: 'financingType', width: 110 },
    { text: 'Cash Price', value: 'payoff', width: 200 },
    { text: 'Price With Interest', value: 'priceWithInterest', width: 200 },
    { text: 'Down Payment', value: 'downPayment', width: 200 },
    { text: 'Balloon Payment', value: 'balloonPayment.amount', width: 200 },
    { text: 'Dat Security Deposit', value: 'datSecurityDeposit', width: 200 },
    { text: 'Monthly Payment', value: 'monthlyPayments[0].amount', width: 200 },
    { text: 'Remaining Number of Payments', value: 'remainingPeriod', width: 200 },
    { text: 'Payoff / Paid With Cash', value: 'payoff', width: 200 },
    { text: 'Paid', value: 'payoff', width: 200 },
    { text: 'Note', value: 'note', width: 200 },
    { text: 'Status', value: 'status', width: 200 },
    { text: 'Actions', value: 'actions', align: 'center' },
  ],
  CONTRACT_HEADERS: [
    {
      align: 'center',
      sortable: false,
      text: 'Contract ID',
      value: 'id',
      width: 120,
    },
    {
      align: 'center',
      sortable: false,
      text: 'File',
      value: 'file',
      width: 48,
    },
    {
      align: 'center',
      sortable: false,
      text: 'Date',
      value: 'date',
      width: 140,
    },
    {
      align: 'center',
      sortable: false,
      text: 'Contract units',
      value: 'contractUnits',
      width: 100,
    },
    {
      align: 'center',
      sortable: false,
      text: '',
      value: 'unitsCount',
      width: 80,
    },
    {
      align: 'center',
      sortable: false,
      text: 'Financial Institution',
      value: 'bank',
      width: 240,
    },
    {
      align: 'center',
      sortable: false,
      text: 'Company name',
      value: 'company',
      width: 160,
    },
    {
      align: 'center',
      sortable: false,
      text: 'Due date',
      value: 'dueDate',
      width: 180,
    },
    {
      align: 'center',
      sortable: false,
      text: 'Principal amount',
      value: 'principalAmount',
      width: 180,
    },
    {
      align: 'center',
      sortable: false,
      text: 'Payment period',
      value: 'paymentPeriod',
      width: 100,
    },
    {
      align: 'center',
      sortable: false,
      text: 'Monthly payment',
      value: 'monthlyPayment',
      width: 180,
    },
    {
      align: 'center',
      sortable: false,
      text: 'Owning balance',
      value: 'owningBalance',
      width: 180,
    },
    {
      align: 'center',
      sortable: false,
      text: 'Security deposit',
      value: 'datSecurityDeposit',
      width: 180,
    },
    {
      align: 'center',
      sortable: false,
      text: 'Down payment',
      value: 'downPayment',
      width: 200,
    },
    {
      align: 'center',
      sortable: false,
      text: 'Balloon payment',
      value: 'balloonPayment',
      width: 200,
    },
    {
      align: 'center',
      sortable: false,
      text: 'Interest',
      value: 'interest',
      width: 140,
    },
    {
      align: 'center',
      sortable: false,
      text: 'Last payment date',
      value: 'lastPaymentDate',
      width: 140,
    },
    {
      align: 'center',
      sortable: false,
      text: 'Note',
      value: 'note',
      width: 300,
    },
    {
      align: 'center',
      sortable: false,
      text: 'Actions',
      value: 'actions',
      width: 90,
    },
  ],
  CONTRACT_WITH_CASH_HEADERS: [
    {
      align: 'center',
      sortable: false,
      text: 'Invoice ID',
      value: 'id',
      width: 120,
    },
    {
      align: 'center',
      sortable: false,
      text: 'File',
      value: 'file',
      width: 48,
    },
    {
      align: 'center',
      sortable: false,
      text: 'Date',
      value: 'date',
      width: 140,
    },
    {
      align: 'center',
      sortable: false,
      text: 'Contract units',
      value: 'contractUnits',
      width: 100,
    },
    {
      align: 'center',
      sortable: false,
      text: 'Unit number',
      value: 'unitsCount',
      width: 80,
    },
    {
      align: 'center',
      sortable: false,
      text: 'Dealer',
      value: 'bank',
      width: 240,
    },
    {
      align: 'center',
      sortable: false,
      text: 'Company name',
      value: 'company',
      width: 160,
    },
    {
      align: 'center',
      sortable: false,
      text: 'Cash price',
      value: 'principalAmount',
      width: 180,
    },
    {
      align: 'center',
      sortable: false,
      text: 'Security deposit',
      value: 'datSecurityDeposit',
      width: 180,
    },
    {
      align: 'center',
      sortable: false,
      text: 'Down payment',
      value: 'downPayment',
      width: 200,
    },
    {
      align: 'center',
      sortable: false,
      text: 'Note',
      value: 'note',
      width: 300,
    },
    {
      align: 'center',
      sortable: false,
      text: 'Actions',
      value: 'actions',
      width: 90,
    },
  ],
}
