<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="formattedFiles"
      disable-sort
      class="elevation-1"
    >

      <template v-slot:item.name="{ item }">
        {{ getFileName(item.url) }}
      </template>

      <template v-slot:item.preview="{ item }">
        <v-btn icon @click="openPreview(item.url)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="showPreviewDialog" max-width="90vw" max-height="90vh">
      <v-card>
        <v-card-title class="headline">File Preview</v-card-title>
        <iframe
          v-if="fileUrl"
          :src="fileUrl"
          style="width: 100%; height: calc(90vh - 120px)"
          frameborder="0"
        ></iframe>
        <v-card-actions>
          <v-btn text @click="showPreviewDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

export default {
  name: 'FilesTable',
  props: {
    files: {
      type: [Array, String, File],
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      headers: [ 
        { text: 'File name', value: 'name' },
        { text: 'Preview file', value: 'preview' }
      ],
      showPreviewDialog: false,
      fileUrl: null,
    }
  },
  computed: {
    formattedFiles() {
      if (Array.isArray(this.files)) {
        return this.files.map((file) => {
          if (typeof file === 'string') {
            return { url: file }
          } else if (file instanceof File) {
            return { url: URL.createObjectURL(file), name: file.name }
          }
          return {}
        })
      } else if (typeof this.files === 'string') {
        return [{ url: this.files }]
      }
      return []
    },
  },
  methods: {

    getFileName(fileUrl) {
      return fileUrl.split('/').pop()
    },

    openPreview(fileUrl) {
      this.fileUrl = fileUrl
      this.showPreviewDialog = true
    },
  },
}
</script>
<style scoped>
.actionsBar {
  height: 48px;
}
.customTextField {
  width: 350px;
}
.customTd {
  width: 200px;
}
</style>
